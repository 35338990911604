h1, h2, h3, h4, h5, h6 {
	color: $color-10;
}

.login-container {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	gap: 40px;
	@include media-breakpoint-up(md) {
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.box {
		.btn {
			width: 100%;
		}
	}
}

.login-form {
	form {
		display: flex;
		flex-direction: column;
	}

	h3 {
		align-items: center;
		display: flex;

		&::after {
			background-image: url(/assets/img/icons/login.svg);
			content: "";
			flex: 0 0 64px;
			height: 53px;
			margin-left: $grid-gutter-width-half;
			width: 64px;
		}
	}
}

.login-title {
	text-align: center;
	font-size: 40px;
	line-height: 54px;
	font-weight: 700;
	margin: 0px;

	@include media-breakpoint-up(md) {
		font-size: 50px;
		line-height: 64px;
	}

	&::before {
		content: '';
		background-image: url(/assets/img/icons/user-lock.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		width: 56px;
		height: 47px;
		display: block;
		margin: 0 auto 10px;

		@include media-breakpoint-up(md) {
			width: 64px;
			height: 53px;
			margin: 0 auto 15px;
		}
	}

	& + .login-container {
		margin-top: 24px;

		@include media-breakpoint-up(md) {
			margin-top: 48px;
		}
	}
}

.login-box-subtitle {
	text-transform: uppercase;
	margin: 0px 0px 8px;
	font-size: 15px;
	line-height: 32px;
	letter-spacing: 0.5px;
	font-weight: 400;
	@include media-breakpoint-up(md) {
   		line-height: 1;
	}
}

.login-box-title {
	margin: 0px 0px 32px;
	font-size: 32px;
	line-height: 44px;
	font-weight: 700;
	@include media-breakpoint-up(md) {
   		line-height: 1;
	}
}